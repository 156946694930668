import React from "react";
import Homepage from "../components/Home"
import ParticlesBackground from '../components/Particles'



function Home() {
  return (
    <div>
    <ParticlesBackground/>
    <Homepage/>
    </div>
  );
}

export default Home;